const {PublishMethods, factory} = require('@wix/web-bi-logger/dist/src/logger')
const {create, phasesConfig} = require('@wix/fedops-logger')

const DEFAULT_ENDPOINT = 'bolt-viewer'
const BI_ENDPOINT = 'bolt-performance'

const naiveParamsConvertor = parmas => {
    switch (parmas.evid) {
        case 14:
            parmas.evid = 21
            break
        case 11:
            parmas.evid = 23
            break
        case 13:
            parmas.evid = 22
            break
        case 15:
            parmas.evid = 24
            break
        case 16:
            parmas.evid = 25
            break
        case 17:
            parmas.evid = 26
            break
        case 18:
            parmas.evid = 27
            break
    }
    return {type: 'reportBI', ...parmas}
}

const serverLoggerFactory = (biStore, shouldBeMutedForFedOps = () => false) => factory({
    publishMethod: PublishMethods.PostMessage
}).setMuted(shouldBeMutedForFedOps())
    .withUoUContext({
        msid: biStore.msid,
        visitorId: biStore.visitorId,
        siteMemberId: () => biStore.siteMemberId
    })
    .updateDefaults({
        is_rollout: biStore.is_rollout,
        st: biStore.site_type,
        isp: biStore.is_premium,
        dc: biStore.data_center,
        ish: biStore.is_headless
    })
    .withTransformer({
        [PublishMethods.PostMessage]: naiveParamsConvertor
    })

const clientLoggerFactory = (biStore, shouldBeMutedForFedOps = () => false) => factory({
    publishMethod: PublishMethods.Auto
})
    .setMuted(shouldBeMutedForFedOps())
    .withUoUContext({
        msid: biStore.msid,
        visitorId: biStore.visitorId,
        siteMemberId: () => biStore.siteMemberId
    })
    .updateDefaults({
        is_rollout: biStore.is_rollout,
        st: biStore.site_type,
        isp: biStore.is_premium,
        dc: biStore.data_center,
        ish: biStore.is_headless
    })
    .withTransformer(naiveParamsConvertor)

const getFedOpsServerLogger = (biStore, muteFunc) => create(DEFAULT_ENDPOINT, {
    endpoint: BI_ENDPOINT,
    isServerSide: true,
    biLoggerFactory: serverLoggerFactory(biStore, muteFunc),
    phasesConfig: phasesConfig.SEND_ON_FINISH,
    metasiteId: biStore.msid
})

const getFedOpsClientLogger = (biStore, muteFunc) => create(DEFAULT_ENDPOINT, {
    endpoint: BI_ENDPOINT,
    isServerSide: false,
    biLoggerFactory: clientLoggerFactory(biStore, muteFunc),
    phasesConfig: phasesConfig.SEND_ON_FINISH,
    metasiteId: biStore.msid
})

module.exports = {
    getFedOpsClientLogger,
    getFedOpsServerLogger
}
